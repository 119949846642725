// await in loop used for retries. Regarding docs, it's better to disable this rule
// https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it

/* eslint-disable no-await-in-loop */
import { logger } from '../../utils';
import { cache } from '../redis-cache';

type HierarchyDataProps = {
  id: string;
  locale: string;
  key?: string;
  staging?: boolean;
};

export const getHierarchyData = async ({ id, locale, key, staging }: HierarchyDataProps): Promise<any[]> => {
  const responses: any[] = [];

  if (!id) {
    return responses;
  }

  const baseUrl = staging
    ? `https://${process.env.AMPLIENCE_HUB_STAGING}.staging.bigcontent.io/content/filter`
    : `https://${process.env.AMPLIENCE_HUB_NAME}.cdn.content.amplience.net/content/filter`;

  // limit = 2 to only get two pages (max 24 responses)
  let limit = 2;
  let cursor = '';

  while (limit > 0) {
    limit -= 1;
    const requestBody = {
      filterBy: [
        {
          path: '/_meta/hierarchy/parentId',
          value: id,
        },
      ],
      parameters: {
        depth: 'all',
        locale,
      },
      sortBy: {
        ...{ key },
      },
      ...(!!cursor && { page: { cursor } }),
    };

    try {
      const HIERARCHY_DATA_CACHE_KEY = `HIERARCHY_DATA_${id}_${locale}_${key}__${staging}_${''}_${cursor}`;
      const cachedHierarchyData = await cache().get(HIERARCHY_DATA_CACHE_KEY);

      // If cache is available then return catch as long as it is not staging
      if (cachedHierarchyData && !staging) {
        responses.push(...(cachedHierarchyData.responses ?? []));
        if (!cachedHierarchyData.page?.nextCursor) break;
        cursor = cachedHierarchyData.page.nextCursor;
      } else {
        const response = await fetch(baseUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`Fetch failed with status ${response.status}, ${response.statusText}`);
        }

        const data = await response.json();
        responses.push(...(data?.responses ?? []));

        await cache().set(HIERARCHY_DATA_CACHE_KEY, data, { ttl: 60 * 10 });

        if (!data?.page?.nextCursor) break;
        cursor = data.page.nextCursor;
      }
    } catch (error) {
      logger.debug({ error }, 'Error in getHierarchyData');
      break;
    }
  }

  return responses;
};
