import { getHierarchyData } from './getHierarchyData';

export const getLinkedContentWithChildData = async (linkedContent: any, locale: string, staging?: boolean) =>
  linkedContent
    ? Promise.all(
        linkedContent.map(async (linkedData: any) => {
          const hasChildNodes = !!linkedData._meta?.hierarchy;
          if (hasChildNodes) {
            const linkedContentChildData = await getHierarchyData({
              id: linkedData._meta.deliveryId as string,
              locale,
              key: 'default',
              staging,
            });

            return { ...linkedData, ...{ linkedContentChildData } };
          }

          return linkedData;
        }),
      )
    : [];
