export const getLayoutContentWithChildData = (layoutContent: any, linkedContent: any) =>
  layoutContent?.map((layoutContentData: any) => {
    const { navigationList: navigationListData, featuredCards: featuredCardsComponent } = layoutContentData;
    let linkedContentChildData;

    if (navigationListData) {
      const navigationList = navigationListData.map((navigationData: any) => {
        const { featuredLinks: featuredLinksComponent, category: categoryComponent } = navigationData;

        if (featuredLinksComponent) {
          linkedContentChildData = linkedContent.find(
            (linkedContentData: any) => linkedContentData._meta.deliveryId === featuredLinksComponent.id,
          );
          const featuredLinks = { ...navigationData.featuredLinks, ...{ linkedContentChildData } };
          return { ...navigationData, ...{ featuredLinks } };
        }

        if (categoryComponent) {
          let categoryLevelFeaturedCards;
          const linkedContentCategoryChildData = linkedContent.find(
            (linkedContentData: any) => linkedContentData._meta.deliveryId === categoryComponent.id,
          );

          if (linkedContentCategoryChildData?.categoryLevelFeaturedCards) {
            categoryLevelFeaturedCards = linkedContentCategoryChildData?.categoryLevelFeaturedCards.map(
              (categoryLevelFeaturedCard: any) =>
                linkedContent.find(
                  (linkedContentData: any) => linkedContentData._meta.deliveryId === categoryLevelFeaturedCard.id,
                ),
            );
          }
          linkedContentChildData = { ...linkedContentCategoryChildData, ...{ categoryLevelFeaturedCards } };

          const category = { ...navigationData.category, ...{ linkedContentChildData } };
          return { ...navigationData, ...{ category } };
        }
        return navigationData;
      });

      return { ...layoutContentData, ...{ navigationList } };
    }

    if (featuredCardsComponent) {
      linkedContentChildData = linkedContent.find(
        (linkedContentData: any) => linkedContentData._meta.deliveryId === featuredCardsComponent.id,
      );
      const featuredCards = { ...layoutContentData.featuredCards, ...{ linkedContentChildData } };
      return { ...layoutContentData, ...{ featuredCards } };
    }

    return layoutContentData;
  });
